@import './common.scss';

body {
  font-size: 14px;
  color: #444;
  line-height: 1.41;
  font-family: "Helvetica Neue", Helvetica, "Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
  position: relative;
  background: #fdfdfd;
}

.main {
  width: 1100px;
  margin: 0 auto;

  .header {
    padding: 20px 0;

    .logo {
      width: 131px;
      height: 40px;
    }

    .menu-item {
      margin-left: 20px;
      line-height: 40px;
      color: #000000;
      cursor: pointer;

      &:hover {
        color: rgb(157, 41, 51);
      }
    }

    .search-wrap {

      .search-info {
        position: relative;
        height: 38px;
        width: 354px;
        background: #f5f5f5;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        .search-type-value {
          width: 80px;
          cursor: pointer;

          span {
            &:after {
              content: ">";
              margin-left: 6px;
              color: #bbb;
            }
          }
        }

        input {
          flex: 1;
        }

        .search-type {
          position: absolute;
          left: 0;
          top: 100%;
          display: none;
          z-index: 1;

          &.show {
            display: block;
          }

          p {
            width: 80px;
            line-height: 28px;
            text-align: center;
            background: #F5F5F5;
            border-top: 1px solid rgba(187, 187, 187, 0.1);
            cursor: pointer;

            span {
              &.active {
                color: #FA8A4C;
              }
            }

          }
        }
      }
    }

    .submit {
      cursor: pointer;
      width: 122px;
      color: #FFFFFF;
      background-image: linear-gradient(-207deg, #FA9D4B 0%, #FA8A4B 100%);
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

      &:hover {
        background-image: linear-gradient(-207deg, #FA9D4B - #111 0%, #FA8A4B - #111 100%);
      }
    }
  }

  .container {
    margin-top: 17px;
    width: 1123px;

    &:after {
      clear: both;
      content: '';
      display: block;
      width: 0;
      height: 0;
      visibility: hidden;
    }

    .news-container {
      position: relative;
      float: right;
      width: 351px;
      height: 520px;
      margin-right: 23px;
      margin-bottom: 20px;
      background: #fff;

      &>.tip {
        position: absolute;
        top: -24px;
        right: 0;
        font-size: 14px;
        color: #FA8A4C;
      }

      .news-wrap {
        width: 100%;
        height: 520px;
        overflow: auto;
        position: relative;

        &:after {
          content: "";
          display: none;
          width: 282px;
          height: 95px;
          position: absolute;
          left: 0;
          bottom: 0;
          background-image: linear-gradient(-180deg, rgba(251, 251, 251, 0.00) 0%, rgba(245, 245, 245, 0.98) 100%);
          z-index: 9;
          pointer-events: none;
        }

        .news-list {
          position: relative;
          width: 282px;
          padding: 0 15px;
          background: #F5F5F5;
          float: left;

          &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 315px;
            width: 1px;
            height: 100%;
            background: #F2F2F2;
          }

          .news-item {
            padding: 20px 0 3px;
            border-bottom: 1px solid #EEEEEE;
            position: relative;

            &:first-child {
              .news-time {
                top: 50px;
              }

              .news-date {
                display: block;
                position: absolute;
                padding-right: 5px;
                width: 65px;
                left: 267px;
                top: 0px;
                background: #fff;
                z-index: 9;

                p {
                  font-size: 14px;
                  color: #FA8A4C;
                  text-align: right;
                  line-height: 20px;

                  &:last-child {
                    font-size: 22px;
                    color: #FA8A4C;
                    line-height: 30px;
                  }
                }
              }
            }

            &:last-child {
              border-bottom: none;
            }

            .news-date {
              display: none;
            }

            .news-title {
              font-size: 16px;
              color: #444444;
              line-height: 22px;
              cursor: pointer;
            }

            .news-Keywords {
              margin-top: 4px;
              font-size: 12px;
              color: #999999;
              line-height: 24px;
            }

            .news-time {
              position: absolute;
              width: 65px;
              left: 267px;
              top: 16px;
              font-size: 16px;
              color: #9B9B9B;
              text-align: center;
              line-height: 30px;
              background: #fff;
              z-index: 9;
            }
          }
        }
      }
    }

    .nav-item {
      float: left;
      width: 351px;
      height: 250px;
      padding: 18px;
      margin-right: 23px;
      margin-bottom: 20px;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.04);
      background: #fff;

      blockquote {
        margin: 0 0 10px;
        padding-left: 10px;
        font-size: 16px;
        line-height: 20px;
        color: #444444;
        border-left: 5px solid #FA8A4C;
      }

      a {
        position: relative;
        float: left;
        width: 33.33%;
        text-decoration: none;

        &:hover {
          .nav-item-name {
            background-color: #2c3e50;
            color: #ffffff;
            font-size: 20px;
          }
        }

        .nav-item-name-light {
          color: #fa8a4c;
          font-weight: bold;
        }

        span {
          display: block;
          width: 100%;
          height: 45px;
          padding: 0 5px;
          text-align: left;
          line-height: 45px;
          color: #444;
          border-radius: 2px;
          overflow: hidden;
          transition: all 0.2s ease;
        }

        .nav-item-edit {
          position: absolute;
          left: 0;
          top: 0;
          text-align: center;
          display: none;
        }
      }
    }
  }

  .container-edit {
    .nav-item {
      a {
        &:hover {
          .nav-item-name {
            background-color: #ffffff;
            color: #ffffff;
            font-size: 4px;
          }

          .nav-item-edit {
            background-color: #1e50a2;
          }
        }

        .nav-item-edit {
          display: block;
          background-color: #ffffff;
          color: #ffffff;
          opacity: 0.7;
        }
      }
    }
  }
}

.copyright {
  padding: 30px 0 10px;
  color: #666;
  font-size: 12px;
  text-align: center;
}

.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  .tip-contain {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;
    background: #fff;
    text-align: center;

    * {
      max-width: 200px;
    }
  }

  .modal-content {
    width: 600px;
    margin: 30px auto;
    background: #f4f4f4;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, .2);
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);

    .modal-header {
      position: relative;
      padding: 15px;
      border-bottom: 1px solid #e5e5e5;

      h3 {
        font-size: 24px;
        margin-right: 20px;
        display: inline-block;
      }

      button {
        float: right;
        color: rgba(0, 0, 0, .2);
        font-size: 21px;
        font-weight: bold;
        background: #f4f4f4;
        border: 0;
        cursor: pointer;
      }
    }
  }
}

.hotkey-modal {
  width: 100%;
  padding: 20px 30px;

  .hotkey-row {
    width: 100%;
    overflow: hidden;

    &:nth-child(2) {
      padding-left: 20px;
    }

    &:nth-child(3) {
      padding-left: 40px;
    }

    &:nth-child(4) {
      padding-left: 60px;
    }

    &:nth-child(5) {
      padding-left: 80px;

      .hotkey-key {
        width: 400px;
      }
    }

    .hotkey-key {
      display: block;
      float: left;
      margin: 0 4px 4px 0;
      width: 45px;
      height: 45px;
      text-align: center;
      background-color: #2c3e50;
      color: #ffffff;
      border-radius: 5px;
      border: 1px solid #2c3e50;
      overflow: hidden;
      cursor: pointer;

      &:hover {
        border-color: #0DA081;
        background-color: #0DA081;
      }

      .letter {
        display: block;
        font-size: 16px;
      }

      .website-name {
        display: block;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.custom-info {
  padding: 10px;

  p {
    text-align: center;
    line-height: 2;

    &:last-child {
      color: #4169e1;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.edit-info {
  padding: 15px 30px;

  input,
  button {
    width: 100%;
    height: 30px;
    margin-bottom: 10px;
    line-height: 30px;
    border: 1px solid #dcdddd;
    border-radius: 3px;
    background-color: #ffffff;
  }

  button {
    margin-top: 10px;
    color: #1e50a2;
    cursor: pointer;

    &:hover {
      background-color: #1e50a2;
      color: #ffffff;
      text-decoration: underline;
    }
  }

  .hotkey-modal-edit {
    padding: 10px;
    border-radius: 3px;
    background-color: #ffffff;

    .hotkey-row {
      &:nth-child(5) {
        .hotkey-key {
          width: 360px;
        }
      }

      .hotkey-key {
        width: 40px;
        height: 40px;
        background-color: #e8eff7;
        border: 1px solid #dcdddd;
        color: #494a41;

        &:hover {
          border-color: #0DA081;
          background-color: #0DA081;
          color: #ffffff;
        }

        .letter {
          font-size: 14px;
        }
      }
    }
  }

  &>p {
    width: 100%;
    text-align: center;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.customing-info {
  position: fixed;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 500px;
  background-color: #0DA081;
  color: #ffffff;
  text-align: center;
  opacity: 0.8;
  z-index: 15;
  border-radius: 0px 0px 5px 5px;
  overflow: hidden;

  .customing-info-title {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
  }

  .customing-info-button {
    line-height: 30px;
    color: #ffffff;
    cursor: pointer;

    &:hover {
      background: #f07973;
    }
  }
}

.mCSB_scrollTools {
  width: 4px;
}

.mCSB_inside>.mCSB_container {
  margin-right: 0;
}

.qr-code {
  position: fixed;
  right: 0;
  top: 30%;
  z-index: 10;

  .content {
    background: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.04);
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }
  }

  img {
    width: 120px;
    height: 120px;
  }

  p {
    width: 120px;
    text-align: center;
    line-height: 1.41;
  }
}