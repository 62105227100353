// reset
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

input {
  border: none;
  outline: none;
  -webkit-appearance: none;
  border-radius: 0;
  background: transparent;
}

// common scss
.flex {
  display: flex;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.overflow3 {
  overflow: hidden;
  text-overflow: ellipsis; //文本溢出显示省略号
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}


// loading ui
.loading-more {
  text-align: center;
  .tips{
    padding: 10px 0;
    color: #ccc;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 34px;
}

.lds-ellipsis div {
  position: absolute;
  top: 12px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #999;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(19px, 0);
  }
}